<template>
  <v-dialog
    v-model="shower"
    persistent
    :max-width="widthDialog"
    style="overflow-x: hidden"
  >
    <v-card>
      <v-card-title class="teal darken-3" dark>
        <span
          class="font-weight-medium white--text text-center"
          style="font-size: 18px"
        >
          Verify One ID & OTP
        </span>
      </v-card-title>

      <v-card-text class="mt-6 pt-3">
        <v-form ref="form" :disabled="this.isSendOtp">
          <v-text-field
            class="font-weight-small username-otp"
            clear-icon="mdi-close"
            :hint="$t('otp_botonebox.username_oid')"
            clearable
            :disabled="isloading || isOtptimeout"
            :rules="[rules.required]"
            v-model.trim="actual_username"
            label="Username or Email"
            outlined
            dense
            :error-messages="actually_username"
            @blur="$v.actual_username.$touch()"
          >
          </v-text-field>
          <v-textarea
            :disabled="isloading || isOtptimeout"
            outlined
            label="Reason to login"
            clear-icon="mdi-close"
            dense
            class="font-weight-medium mt-1"
            :rules="requiredReason"
            :error-messages="reasone_error_message"
            :hint="$t('otp_botonebox.reason_hint')+`&quot;botonebox&quot;`"
            @blur="$v.reason_login.$touch()"
            v-model.trim="reason_login"
          >

          </v-textarea>
        </v-form>
        <v-divider></v-divider>
        <v-flex
          dlex
          class="pb-5 justify-center align-center mt-5"
          v-if="isSendOtp === true"
        >
          <div>
            <div class="mt-n2 pb-2">
              <span class="text-subtitle-2">
                {{ $t('otp_botonebox.enter_otp') }} : &nbsp;&nbsp;
              </span>
              &nbsp;&nbsp;
              <span class="text-subtitle-2 font-weight-black" >
                &nbsp;&nbsp; Ref <{{ ref_otp }}>
              </span>
            </div>
            <div class="testForm" v-if="isResendOTPImmi === false">
              <otp-input
                class="testotp"
                ref="otpVerifyRefs"
                @on-complete="onCompleteOTP"
                @on-changed="onChangedOTP"
                @on-paste="onPasteOTP"
                :separateInputClass="otpclass"
                :autoFocus="false"
                activeWrapperClass="mai-finished"
                placeholder=""
                :isDisabled="isOtptimeout || isloading"
                :isValid="false"
                :digits="6"
                type="text"
              >
              </otp-input>
            </div>
            <div v-else class="py-2 text-center">
              <v-progress-circular
                color="teal"
                indeterminate
                width="2"
                size="20"
              >

              </v-progress-circular>
            </div>
            <div
              v-if="this.checkIsvalidOTP === false"
              d-flex
              class="mt-2 text-center"
              style="background-color: rgb(230, 230, 230)"
            >
              <div class="align-center justify-center">
                <v-icon color="warning"> mdi-alert-circle </v-icon>
                <span class="text-subtitle-2 font-weight-bold ml-1">
                  {{ messageerrorotp }}
                </span>
              </div>
            </div>
          </div>
          <div class="align-self-end mt-3 pb-0 d-flex">
            <span :class="coveredTimeFormat_style">
              {{ coverTimeFormat }}
            </span>
            <v-spacer></v-spacer> 
            <v-icon @click="resendOTPimmediate" :disabled="isloading" v-if="!isOtptimeout">
              mdi-refresh
            </v-icon>
          </div>
        </v-flex>
      </v-card-text>
      <v-card-actions v-if="isSendOtp">
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="error"
          class="text-button"
          :style="btnAction"
          :width="widthResendBtn"
          @click="closeDialog"
          :disabled="isloading"
        >
          <span> {{ $t('otp_botonebox.cancel_otp_btn') }} </span>
        </v-btn>
        <v-btn
          class="text-button"
          depressed
          color="teal"
          dark
          :style="btnAction"
          :width="widthResendBtn"
          v-if="isOtptimeout"
          @click="fn_resend_otp"
        >
          <v-progress-circular
            v-if="isloading"
            indeterminate
            color="white"
            width="2"
            size="16"
            class="mr-1"
          ></v-progress-circular>
          <v-icon small class="mr-1" v-else> mdi-replay </v-icon>
          <span > {{ $t('otp_botonebox.resend_otp_btn') }} </span>
        </v-btn>
        <v-btn
          depressed
          class="text-button"
          color="teal"
          :dark="isloading ? false : true"
          :style="btnAction"
          v-if="!isOtptimeout"
          :width="widthResendBtn"
          @click="checkvalidotp"
          :disabled="isloading"
        >
          <span> {{ $t('otp_botonebox.verify_otp') }} </span>
        </v-btn>
      </v-card-actions>

      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="error"
          class="text-button"
          :style="btnAction"
          :width="widthResendBtn"
          :disabled="isloading"
          @click="closeDialog"
        >
          <span> {{ $t('otp_botonebox.cancel_otp_btn') }}  </span>
        </v-btn>
        <v-btn
          depressed
          color="teal"
          class="text-button"
          :dark="isloading ? false : true"
          :style="btnAction"
          :width="widthResendBtn"
          @click="checkvalidusername"
          :disabled="isloading"
        >
          <v-progress-circular
            v-if="isloading"
            indeterminate
            color="white"
            width="2"
            size="18"
            class="mr-1"
          ></v-progress-circular>
          <span> {{ $t('otp_botonebox.send_otp_btn') }} </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import OtpInput from "otp-input-vue2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import formatDatetime from "../../globalFunctions/formatDatetime";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";
import axios from "axios";

export default {
  mixins: [validationMixin],
  validations: {
    actual_username: { required },
    reason_login: { required }
  },
  components: {
    OtpInput,
  },
  props: ["showVerify", "infor_data"],
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    // Computed class or style
    otpclass() {
      if (this.checkIsvalidOTP === true) {
        console.log("True ");
        return "mai-classsed";
      } else {
        console.log("Falsde ");
        return "mai-classsed error-class";
      }
    },
    heightDialog() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    widthResendBtn() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "40%";
        default:
          return "35%";
      }
    },
    widthDialog() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 340; // 340
        default:
          return 400;
      }
    },
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    coveredTimeFormat_style() {
      if (this.isOtptimeout) {
        return "subtitle-2 red--text";
      } else {
        return `subtitle-2`;
      }
    },

    // Computed logic
    coverTimeFormat() {
      if (this.isOtptimeout) {
        return "OTP Time out";
      } else {
        return `Time Remaining : ${this.secondsToMinSec(
          this.countdowntime_otp
        )}`;
      }
    },
    actually_username() {
      const errors = [];
      if (this.checkIsvalidUsername === false) {
        console.log("messageerrorusername ", this.messageerrorusername)
        errors.push(this.messageerrorusername);
        return errors;
      } else {
        if (!this.$v.actual_username.$dirty) return errors;
        !this.$v.actual_username.required &&
          errors.push(this.$t("otp_botonebox.validator_username"));
        return errors;
      }
    },

    reasone_error_message () {
      const errors = [];
      if (this.checkIsValidReason === false) {
        errors.push(this.messageerrorreason);
        return errors;
      } else {
        if (!this.$v.reason_login.$dirty) return errors;
        !this.$v.reason_login.required &&
          errors.push(this.$t("otp_botonebox.validator_reason"));
        return errors;
      }
    },

    shower: {
      get() {
        if (this.showVerify === true) {
          console.log("SJOW ", this.showVerify);
          this.showdialogOpt = this.showVerify;
          return this.showdialogOpt;
        } else {
          this.showdialogOpt = this.showVerify;
          return this.showdialogOpt;
        }
      },
    },
  },

  data: function () {
    return {
      data_id_otp: "",
      ref_otp: "",
      actual_username: "",
      reason_login: "",
      otp_verify: "",
      messageerrorotp: "",
      messageerrorusername: "",
      messageerrorreason: "",
      otpStep: 1,
      isResendOTPImmi: false,
      isSendOtp: false,
      isOtptimeout: false,
      isCompleteOtp: false,
      checkIsvalidOTP: true,
      checkIsvalidUsername: true,
      checkIsValidReason: true,
      isloading: false,
      interval: null,
      beforeUnloadListener: null,
      showdialogOpt: false,
      countdowntime_otp: 60*5,
      countdowntime_otp_format: "",
      requiredReason: [
        (value) => !!value || this.$t("otp_botonebox.validator_reason"),
        (value) => value.length >= 5 || this.$t("otp_botonebox.validator_reason_charactors"),
        (value) =>  (this.fn_checkreason(value)) || this.$t("otp_botonebox.validator_reason_special"),
      ],
      rules: {
        required: (value) => !!value || this.$t('otp_botonebox.require_username'),
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  methods: {
    async resendOTPimmediate () {
      this.isloading = true
      this.isResendOTPImmi = true
      if(this.data_id_otp !== "" ){
        clearInterval(this.interval)
        let payload = { otp: "", status: "N", data_id: this.data_id_otp };
        let result = await this.fn_cancel_otp(payload);
        if (result.status === "OK") {
          this.data_id_otp = ""
          this.fn_resend_otp()
        }
      }
    },
    fn_checkreason(value) {
      const pattern = /^[a-zA-Z0-9ก-๙.\s@,()&+,:;=?@#|'<>^*()%!]*$/;
      if (pattern.test(value) == true) {
        this.checkIsValidReason = true;
        return true;
      } else {
        this.checkIsValidReason = false;
        this.messageerrorreason = this.$t("otp_botonebox.validator_reason_special")
        return false;
      }
    },
    startCountdown() {
      this.interval = setInterval(async () => {
        if (this.countdowntime_otp > 0) {
          this.countdowntime_otp--;
        } else {
          // Handle any logic when the countdown reaches zero
          clearInterval(this.interval);
          this.isOtptimeout = true;
          this.isloading = true;
          this.ref_otp = ""
          let payload = {
            otp: "",
            status: "N",
            data_id: this.data_id_otp,
          };
          let result = await this.fn_cancel_otp(payload);
          if (result.status === "OK") {
            this.isloading = false;
          }
        }
      }, 1000);
    },
    async closeDialog() {
      if (this.isSendOtp === true) {
        if (this.isOtptimeout === true) {
          this.$emit("cancelandclosedialogotp");
          return;
        }
        clearInterval(this.interval)
        let payload = { otp: "", status: "N", data_id: this.data_id_otp };
        let result = await this.fn_cancel_otp(payload);
        if (result.status === "OK") {
          this.data_id_otp = ""
          this.$emit("cancelandclosedialogotp");
        }
      } else {
        this.$emit("cancelandclosedialogotp");
      }
    },
    countDown() {
      this.countdowntime_otp--;
    },
    secondsToMinSec(second) {
      const minutes = `${Math.floor(second / 60)}`.padStart(2, "0");
      const seconds = `${second - minutes * 60}`.padStart(2, "0");
      return `${minutes}:${seconds}`;
    },
    onCompleteOTP(otpValue) {
      this.isCompleteOtp = true;
      this.otp_verify = otpValue.toUpperCase();
    },
    onChangedOTP() {
      this.isCompleteOtp = false;
    },
    onPasteOTP (pasteValue) {
      this.isCompleteOtp = true;
      this.otp_verify = pasteValue.toUpperCase()
    },
    onErrorMessageOtp(msg) {
      this.checkIsvalidOTP = false;
      this.messageerrorotp = msg;
      setTimeout(() => {
        this.checkIsvalidOTP = true;
        this.messageerrorotp = "";
      }, 2000);
    },
    triggle_device (msg) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },

    async checkvalidusername() {
      if (this.$v.actual_username.$invalid || this.$v.reason_login.$invalid || !this.checkIsValidReason ) {
        console.log("Check Invalid ", this.$v.actual_username.$invalid);
        console.log("Check Invalid Reason ", this.$v.reason_login.$invalid);
        this.$v.$touch();
        return;
      }
      if(this.reason_login.length < 5){
        this.checkIsValidReason = false
        this.messageerrorreason = this.$t("otp_botonebox.validator_reason_charactors")
        return
      }
      this.isloading = true;
      let payload = {
        user: this.actual_username, //username from receive otp
        reason_access: this.reason_login, //reason to get in
      };
      
      await this.fn_send_otp(payload)
        .then((ress) => {
          if (ress.status === "OK") {
            // initial new time
            if (this.isOtptimeout === true) {
              this.isOtptimeout = false;
            }
            if(this.isResendOTPImmi === true){
              this.isResendOTPImmi = false
            }
            this.countdowntime_otp = 60*5;
            (this.isloading = false), (this.isSendOtp = true);
            this.data_id_otp = ress.data_id;
            this.ref_otp = ress.ref_otp
            this.startCountdown();
          } else if (
            ress.status === "NOT FOUND" ||
            ress.status === "NOT ADD FRIEND"
          ) {
            this.isloading = false;
            this.checkIsvalidUsername = false;
            this.messageerrorusername = ress.message;
            setTimeout(() => {
              this.checkIsvalidUsername = true;
              this.messageerrorusername = "";
            }, 1500);
          } else {
            this.isloading = false;
            this.checkIsvalidUsername = false;
            this.messageerrorusername = "Something wrong, Please try again";
            setTimeout(() => {
              this.checkIsvalidUsername = true;
              this.messageerrorusername = "";
            }, 1500);
          }
        })
        .catch((err) => {
            this.isloading = false;
            this.checkIsvalidUsername = false;
            this.messageerrorusername = "Something wrong, Please try again";
            setTimeout(() => {
              this.checkIsvalidUsername = true;
              this.messageerrorusername = "";
            }, 1500);
        });
    },


    encryptdata (raw_data) {
      let enc = CryptoJS.AES.encrypt(btoa(unescape(encodeURIComponent(raw_data))), 
      CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),{ mode: CryptoJS.mode.ECB, }).toString()
      return enc
    },

    decryptdata (data) {
      var data_decrypt = CryptoJS.AES.decrypt(data, 
          CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      data_decrypt = window.atob(data_decrypt.toString(CryptoJS.enc.Utf8)) 
      return data_decrypt
    },

    async checkvalidotp() {
      if (this.otp_verify === "" || this.isCompleteOtp === false) {
        this.onErrorMessageOtp(this.$t("otp_botonebox.fill_otp"));
        return;
      }
      this.isloading = true;
      let payload = {
        otp: this.otp_verify,
        status: "Y",
        data_id: this.data_id_otp,
      };
      await this.fn_verify_otp(payload).then((res) => {
        if (res.status === "OK") {
          // ใหม่
          VueCookies.set("username_otp", this.encryptdata(this.actual_username))
          // เก่า
          //localStorage.setItem("username_otp", this.encryptdata(this.actual_username));
          let dateConverted = new Date(formatDatetime.formatdatetime_for_create_newdate(res.time_using))
          let encrptDate = this.encryptdata(dateConverted)
          VueCookies.set("login_datetime", encrptDate)
          VueCookies.set("reason_otp", btoa(unescape(encodeURIComponent(this.reason_login)))) // เพราะมีภาษาไทย
          VueCookies.set("data_id", this.data_id_otp)
          clearInterval(this.interval)
          this.$emit("verifyotp");
        } else if (res.status === "INVALID OTP") {
          this.isloading = false;
          this.onErrorMessageOtp(this.$t("otp_botonebox.invalid_otp"));
        } else if (res.status === "AXIOS ERROR") {
          this.isloading = false;
          this.onErrorMessageOtp("Something wrong, Please try again");
        }
      });
    },

    async fn_send_otp(payload) {
      return await this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_CITIZEN + "/api/v1/sent/otp", payload)
        .then((response) => {
          return new Promise((resolve, reject) => {
            if (response.data.status === "OK") {
              setTimeout(() => {
                resolve({
                  status: "OK",
                  data_id: response.data.data_id,
                  ref_otp: response.data.reference_code,
                  message: "",
                });
              }, 500);
            } else {
              if (response.data.errorCode === "ER205" || response.data.errorCode === "ER400") {
                setTimeout(() => {
                  resolve({
                    status: "NOT FOUND",
                    message: this.$t("otp_botonebox.not_found_user"),
                  });
                }, 500);
              } else if (response.data.errorCode === "ER203") {
                setTimeout(() => {
                  resolve({
                    status: "NOT ADD FRIEND",
                    message: this.$t("otp_botonebox.account_not_add"),
                  });
                }, 500);
              } else {
                setTimeout(() => {
                  resolve({ status: "ERROR", message: "" });
                }, 500);
              }
            }
          });
        })
        .catch((err) => {
          setTimeout(() => {
            resolve({
              status: "AXIOS ERROR",
              message: "",
            });
          }, 500);
        });
    },
    async fn_verify_otp(payload) {
      let data = {
        payload: payload,
        infor_granted: this.infor_data
      }
      return this.$store.dispatch('verified_otp_with_botonebox', data)
      .then((res) => {
          return new Promise((resolve, reject) => {
            if (res.status === "OK") {
              resolve({ status: "OK", message: "Verified", time_using: res.time_using });
            } else if (res.status === "INVALID OTP" ){
              resolve({ status: "INVALID OTP", message: this.$t("otp_botonebox.invalid_otp") });
            } else {
              resolve({ status: "AXIOS ERROR", message: "Invalid OTP provided" });
            }
        });
      })
      .catch (() => {
        return resolve({ status: "AXIOS ERROR", message: "Invalid OTP provided" });
      })
    },
    async fn_resend_otp() {
      this.checkvalidusername();
    },
    async fn_cancel_otp(payload) {
      return await this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_CITIZEN + "/api/v1/verify/otp", payload)
        .then((res) => {
          if (res.data.status === "OK") {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve({ status: "OK", msg: "Cancel success" });
              }, 500);
            });
          }
        });
    },
    
  },
  mounted () {},
  beforeDestroy () {
    clearInterval(this.interval)
  }
  
};
</script>


<style scoped>


@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
  }
}
.vue-otp-input >>> .mai-classsed {
  text-align: center;
  font-weight: bold;
  width: 40px;
  height: 40px;
  border: solid 1px rgba(128, 128, 128, 0.7);
  text-transform: uppercase;
  /*  */
}
.vue-otp-input >>> .error-class {
  border: solid 2px rgba(255, 125, 125, 255);
}
/* SCREEN SIZE XS  */
@media screen and (max-width: 350px) {
  .vue-otp-input >>> .mai-classsed {
    width: 30px;
    height: 35px;
    text-transform: uppercase;
  }
}
@media (min-width: 350px) and (max-width: 370px) {
  .vue-otp-input >>> .mai-classsed {
    width: 35px;
    height: 35px;
    text-transform: uppercase;
  }
}

@media (min-width: 370px) and (max-width: 385px) {
  .vue-otp-input >>> .mai-classsed {
    width: 38px;
    height: 35px;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 600px) {
  .vue-otp-input >>> .mai-classsed {
    width: 48px;
    height: 40px;
    text-transform: uppercase;
  }
}
</style>
